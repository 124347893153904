import React, {useEffect,useState} from 'react'
import Modal from 'react-modal'
// import { useHistory } from 'react-router-dom'
import celebrate from '../assets/img/celebrate.gif'
import close from '../assets/img/close.png'

function Playgame(props) {
    // const history = useHistory();
    // const { dataCurrentCp, dataCampaign, dataAPI } = props
    const { dataAPI } = props
    const [hasError, setHasError] = useState(false);
    const [hasErrorMsg, setHasErrorMsg] = useState('คุณยังไม่สามารถเข้า campaign นี้ได้')
    const [isLoaded, setIsLoaded] = useState(false);
    const [dataLoading, setDataLoading] = useState(false)
    const [modalDetail, setModalDetail] = useState(false)
    const [modalAward, setModalAward] = useState(false)
    const [itemIndex, setItemIndex] = useState(0)
    const [itemAwardIndex, setItemAwardIndex] = useState(0)
    const [dataCampaign, setDataCampaign] = useState({});
    const [dataCurrentCp, setDataCurrentCp] = useState([])
    const [dataPrize, setDataPrize] = useState([])
    const [dataPrizeLength, setDataPrizeLength] = useState(0)
    const [playActive, setPlayActive] = useState(true)
    const [congratulation, setCongratulation] = useState(false)
    const [consolidation, setConsolidation] = useState(false)

    let windowW = window.innerWidth;
    let windowH = window.innerHeight;
    
    let timeleft = 0;

    useEffect(() => {
        const fetchStatus =  async() => {
            const res = await fetch(`https://${dataAPI.apiLocation}/ld/api/user/status`, {
            method: 'GET',
            // body: JSON.stringify({
            //   "query": queryFinal
            // }),
            headers: {
                "Authorization": "Bearer "+ dataAPI.token,
                // "x-application-secret-key": dataAPI.apiXKey,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
          });
    
          res
            .json()
            .then(res => {
                console.log('fetch API status: loading..')
    
                console.log('fetch API status:',res)
              if (res.success === 0 || res === null || res === undefined ) {
                setIsLoaded(true);
                setHasError(true);
                console.log('fetch API status: error')
              } else {
                
                setDataCampaign(res)
                setDataCurrentCp(res.current_cp[0])
                setDataPrize(res.prize)
                setDataPrizeLength(res.prize.length)

                setIsLoaded(true)
                console.log('fetch API status: success')

              }
            })
            .catch(err => {
              console.log('fetch API status: error',err);
              setHasError(true)
              setHasErrorMsg('เกิดข้อผิดพลาดไม่สามารถเล่นเกมส์ได้')
            });
          }

          fetchStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataAPI])


    // Logic calculation random frame
    const clearItem = () => {
        // console.log('dataCampaign.prize.length',dataCampaign.prize.length)

        let amountItem = (dataPrizeLength <= 10) ? dataPrizeLength : 10

        for(let i=0; i<amountItem; i++) {
            document.getElementById('list-item').getElementsByTagName('li')[i].style.backgroundImage = 'url('+dataCurrentCp.prize_frame_picture+')'
            document.getElementById('list-item').getElementsByTagName('li')[i].childNodes[0].style.display = 'block'
        }
    }

    // const transparentItem = () => {
    //     for(let i=0; i<dataCampaign.prize.length; i++) {
    //         document.getElementById('list-item').getElementsByTagName('li')[i].style.opacity = '0.8'
    //     }
    // }


    const randomItem = (ind, prize) => {
        clearItem()
        // transparentItem()

        // console.log('ind:',ind,' prize:',prize)

        // var randomIndex = Math.round(Math.random() * (dataCampaign.prize.length-1));

        var Item = document.getElementById("item"+prize[timeleft])
            Item.style.backgroundImage = 'url('+dataCurrentCp.highlighted_frame_picture+')'
            Item.childNodes[0].style.display = 'none'

        // Check timeleft
        // console.log('timeleft',timeleft,', ind',ind)
        let t;
        let speed = 200; // speed (msec)
        if((timeleft+1) < prize.length) {
            t = setTimeout (()=>{ randomItem(ind,prize); } , speed );
        } else {
            clearItem()
            clearTimeout(t)
            
            if(ind<=10) {
                document.getElementById("item"+prize[prize.length -1]).style.backgroundImage = 'url('+dataCurrentCp.highlighted_frame_picture+')'
                document.getElementById("item"+prize[prize.length -1]).childNodes[0].style.display = 'none'
            
                // Show popup Award Congratulation
                setTimeout(()=>{
                    setModalAward(true)
                    setCongratulation(true)
                    setConsolidation(false)
                },1000)
            } else {
                // Show popup Award Consolidation
                setTimeout(()=>{
                    setModalAward(true)
                    setCongratulation(false)
                    setConsolidation(true)
                },1000)
            }
        }

        // Add timeleft
        timeleft++
    }

    // Fetch Start Play
    const fetchStartPlay =  async() => {
        setDataLoading(true)

        const res = await fetch(`https://${dataAPI.apiLocation}/ld/api/user/start_play`, {
        method: 'GET',
        // body: JSON.stringify({
        //   "query": queryFinal
        // }),
        headers: {
            "Authorization": "Bearer "+ dataAPI.token,
            // "x-application-secret-key": dataAPI.apiXKey,
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
      });

      res
        .json()
        .then(res => {
            console.log('fetch API start_play: loading..')

            console.log('fetch API start_play:',res)
          if (res.success === 0 || res === null || res === undefined ) {
            setIsLoaded(true);
            setHasError(true);
            console.log('fetch API start_play: error')
          } else {
            setIsLoaded(true)
            console.log('fetch API start_play: success')

            // eslint-disable-next-line no-undef
            if(Object.keys(res).length === 0 || res.prize_id === null || res.prize_id === undefined ) {
                setHasError(true)
                setHasErrorMsg('เกิดข้อผิดพลาดบางอย่าง  กรุณาลองใหม่')
            } else if(!res.can_play) {
                setHasError(true)
                setHasErrorMsg('ไม่สามารถเล่นเกมส์ได้  กรุณาลองใหม่')
            } else {
                for(let i=0; i<dataPrize.length; i++) {
                    if(dataPrize[i].prize_id === res.prize_id) { // res.prize_id
                        console.log(dataPrize[i].prize_id,'===',res.prize_id,'ind:',i,' prize_order:',dataPrize[i].prize_order)
                        setItemAwardIndex(i)

                        let prize = null;
                        // Check prize length
                        switch (dataPrize[i].prize_order) {
                            case 1:
                                prize = [1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1]
                                break;
                            case 2:
                                prize = [1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2]
                                break;
                            case 3:
                                prize = [1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3]
                                break;
                            case 4:
                                prize = [1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1,7,8,4]
                                break;
                            case 5:
                                prize = [1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1,7,8,4,5]
                                break;
                            case 6:
                                prize = [1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6]
                                break;
                            case 7:
                                prize = [1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1,7]
                                break;
                            case 8:
                                prize = [1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1,7,8]
                                break;
                            case 9:
                                prize = [1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9]
                                break;
                            case 10:
                                prize = [1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10]
                                break;
                            case 11:
                                prize = [1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1]
                                break;
                        
                            default:
                                prize = [1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1,7,8,4,5,6,9,10,3,2,1]
                                break;
                        }

                        console.log('prize:',prize)
                        setDataLoading(false)
                        randomItem(dataPrize[i].prize_order, prize)

                    }
                }
            }
          }
        })
        .catch(err => {
          console.log('fetch API start_play: error',err);
          setHasError(true)
          setHasErrorMsg('เกิดข้อผิดพลาดบางอย่าง  กรุณาลองใหม่')
        });
    }

    const startPlay = () => {
        setPlayActive(false)
        fetchStartPlay()
    }

    const gotoHome = () => {
        setModalAward(false)
        setCongratulation(false)
        setHasError(false)
        setIsLoaded(false)
        // history.push(dataCurrentCp.url_back)
        // window.location = dataCurrentCp.url_back
        window.parent.location = dataCurrentCp.url_back
    }


    Modal.setAppElement('#root');

    if(!isLoaded) {
        return (
          <div className="App">
            <div className="App-loading">
              <p style={{color: '#000000', fontSize: '1.4rem'}}>...Loading...</p>
            </div>
          </div>
        );
    
       }  else {

        return (
            <div className="playgame-page" style={{backgroundImage: 'url('+dataCurrentCp.background_picture+')'}}>
                {
                    dataLoading ?
                        <div className="loading">...Loading...</div>
                    : null
                }
                <br/>
                <br/>
                {
                    playActive ?
                        <button className="btn-gotoplay" onClick={()=>{startPlay()}} style={{backgroundImage: 'url('+dataCurrentCp.button_picture+')', color: '#'+dataCurrentCp.button_action_text_color, fontSize: dataCurrentCp.button_action_text_size+'rem'}}>
                            {dataCurrentCp.button_action_play_page}
                        </button>
                    :
                        <button className="btn-gotoplay" style={{opacity: '0.75', backgroundImage: 'url('+dataCurrentCp.button_picture+')', color: '#'+dataCurrentCp.button_action_text_color, fontSize: dataCurrentCp.button_action_text_size+'rem'}}>
                            {dataCurrentCp.button_action_play_page}
                        </button>
                }
                
                <p style={{color: '#'+dataCurrentCp.view_winner_text_color, fontSize: dataCurrentCp.view_winner_text_size+'rem'}}>กดที่รูปเพื่อดูรายละเอียด</p>
                <br/>


                <div className={`list ${playActive?'':'playactive'}`} id="list-item">
                    <div className="list-section left">
                        <ul>
                            {
                                [0,1,2].map((item,i)=>{
                                    return (
                                        <li key={i+item} id={`item${item+1}`} className="item" onClick={()=>{setModalDetail(true); setItemIndex(item)}} style={{backgroundImage: 'url('+dataCurrentCp.prize_frame_picture+')', height: (windowW/3.25)+15+'px'}}>
                                            {
                                                playActive? 
                                                null
                                                :
                                                <div className="overlay"></div>
                                            }
                                            <img src={dataCampaign.prize[item].prize_image} alt="item prize" />
                                            <div className="number">
                                                {dataCampaign.prize[item].lucky_num}
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="list-section center">
                        <ul>
                            {
                                [6,7,8,9].map((item,i)=>{
                                    return (
                                        <li key={i+item} id={`item${item+1}`} className="item" onClick={()=>{setModalDetail(true); setItemIndex(item)}} style={{backgroundImage: 'url('+dataCurrentCp.prize_frame_picture+')', height: (windowW/3.25)-20+'px'}}>
                                            {
                                                playActive? 
                                                null
                                                :
                                                <div className="overlay"></div>
                                            }
                                            <img src={dataCampaign.prize[item].prize_image} alt="item prize" />
                                            <div className="number">
                                                {dataCampaign.prize[item].lucky_num}
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="list-section right">
                        <ul>{
                                [3,4,5].map((item,i)=>{
                                    return (
                                        <li key={i+item} id={`item${item+1}`} className="item" onClick={()=>{setModalDetail(true); setItemIndex(item)}} style={{backgroundImage: 'url('+dataCurrentCp.prize_frame_picture+')', height: (windowW/3.25)+15+'px'}}>
                                            {
                                                playActive? 
                                                null
                                                :
                                                <div className="overlay"></div>
                                            }
                                            <img src={dataCampaign.prize[item].prize_image} alt="item prize" />
                                            <div className="number">
                                                {dataCampaign.prize[item].lucky_num}
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>

                <Modal 
                    isOpen={modalDetail} 
                    className="_modal box-style modal-winner" 
                    style={{ 
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            zIndex: 3
                        }}
                    }
                >
                    <div className="_info-modal center" style={{ backgroundImage: 'url('+dataCurrentCp.prize_details_frame_picture+')'}}>
                        
                        <div className="btn-close" onClick={()=>setModalDetail(false)}>
                            <img src={close} alt="close" />
                        </div>
                        <div className="_body itemdetail" style={{height: windowH - (windowH/2.5)+'px'}}>
                            <h1 className="itemdetail-header">หมายเลข {dataCampaign.prize[itemIndex].lucky_num}</h1>
                            <div className="itemdetail-img">
                                <img src={dataCampaign.prize[itemIndex].prize_image} alt="item prize" />
                            </div>
                            {/* <h1 className="itemdetail-subheader">รางวัล</h1> */}
                            <p className="itemdetail-subheader">{dataCampaign.prize[itemIndex].prize_name}</p>
                            <p>{dataCampaign.prize[itemIndex].prize_detail}</p>
                        </div>
                        <button className="btn-gotoplay" onClick={()=>setModalDetail(false)} style={{backgroundImage: 'url('+dataCurrentCp.button_picture+')', color: '#'+dataCurrentCp.button_action_text_color, fontSize: dataCurrentCp.button_action_text_size+'rem'}}>
                        ย้อนกลับ
                        </button>
                    </div>
                </Modal>

                <Modal 
                    isOpen={modalAward} 
                    className="_modal box-style modal-winner" 
                    style={{ 
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            zIndex: 3
                        }}
                    }
                >

                    {
                        congratulation ? 
                            <img className="img-congratulation" src={celebrate} alt="item prize" />
                        : null
                    }
                    <div className="_info-modal center" style={{ backgroundImage: 'url('+dataCurrentCp.prize_details_frame_picture+')'}}>
                        
                        <div className="btn-close" onClick={()=>gotoHome()}>
                            <img src={close} alt="close" />
                        </div>
                        <div className="_body itemdetail" style={{height: windowH - (windowH/2.5)+'px'}}>
                            <h1 className="itemdetail-header">{
                                consolidation ?
                                dataCurrentCp.consolidation_text
                                :
                                dataCurrentCp.congratulation_text
                            }</h1>

                            {
                                consolidation ?
                                null :
                                <p>คุณได้หมายเลข {dataCampaign.prize[itemAwardIndex].lucky_num}</p>
                            }
                            <div className="itemdetail-img">
                                {
                                    consolidation ? 
                                    <img src={dataCampaign.prize[dataCampaign.prize.length - 1].prize_image} alt="item prize" />
                                    :
                                    <img src={dataCampaign.prize[itemAwardIndex].prize_image} alt="item prize" />
                                }
                            </div>
                            {/* <h1 className="itemdetail-subheader">รางวัล</h1> */}
                            {
                                consolidation ? 
                                <p className="itemdetail-subheader">{dataCampaign.prize[dataCampaign.prize.length - 1].prize_name}</p>
                                : 
                                <p className="itemdetail-subheader">{dataCampaign.prize[itemAwardIndex].prize_name}</p>
                            }
                            {
                                consolidation ?
                                <p>{dataCampaign.prize[dataCampaign.prize.length - 1].prize_detail}</p>
                                :
                                <p>{dataCampaign.prize[itemAwardIndex].prize_detail}</p>
                            }
                        </div>
                        <button className="btn-gotoplay" onClick={()=>gotoHome()} style={{backgroundImage: 'url('+dataCurrentCp.button_picture+')', color: '#'+dataCurrentCp.button_action_text_color, fontSize: dataCurrentCp.button_action_text_size+'rem'}}>
                        กลับหน้าหลัก
                        </button>
                    </div>
                </Modal>

                <Modal 
                    isOpen={hasError} 
                    className="_modal box-style modal-error" 
                    style={{ 
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            zIndex: 3
                        }}
                    }
                >
                    <div className="_info-modal center" style={{ backgroundColor: '#ffffff'}}>
                        
                        <p>{hasErrorMsg}</p>
                        {/* <p>คุณยังไม่สามารถเข้า campaign นี้ได้</p> */}
                        <br/>
                        <button className="btn-round _blue" onClick={()=>gotoHome()}>
                            ตกลง
                        </button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Playgame
