import React, { useState, useEffect } from 'react'
import './App.scss';
import Main from './pages/Main';

function App() {
  const [hasError, setHasError] = useState(false);
  const [hasErrorMsg, setHasErrorMsg] = useState('คุณยังไม่สามารถเข้า campaign นี้ได้')
  const [isLoaded, setIsLoaded] = useState(false);
  const [dataCampaign, setDataCampaign] = useState({});
  const [dataCurrentCp, setDataCurrentCp] = useState([])
  const [dataAPI, setDataAPI] = useState({})
  
  const url_string = window.location.href;
  const url = new URL(url_string);
  const query = url.searchParams.get("query") || localStorage.getItem('query');
  const queryFinal = query.split(' ').join('%2B');
  // const windowLocation = 'https://vipwallet-sandbox.vivi-th.com';

  useEffect(() => {


    const fetchLogin =  async() => {
      let apiLocation = '';
      let apiXKey = ''
      // let windowParentLocation = ''

      // console.log('windowParentLocation',windowParentLocation);
      console.log('window.location.origin',window.location.origin);
      console.log('?query=',query);

      switch(window.location.origin) {
        // Local
        case "http://localhost:3000": 
          apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
          apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
          // windowParentLocation = 'http://localhost:3000';
          break;

        case "http://localhost:3001": 
          apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
          apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
          // windowParentLocation = 'http://localhost:3001';
          break;

        // Staging
        case "https://luckydraw-sandbox.vivi-th.com":
          apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
          apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
          // windowParentLocation = 'https://th.pmiandu.com/s/e-coupon-testing';
          break;

        // Production
        default:
          apiLocation = 'api.vipwallet.vivi-th.com';
          apiXKey = "756dc69c-4c3e-4724-aa3d-0cf27010c102";
          // windowParentLocation = 'https://th.pmiandu.com/s/e-coupon-testing';
          break;
      }

      const res = await fetch(`https://${apiLocation}/ld/api/user/login`, {
        method: 'POST',
        body: JSON.stringify({
          "query": queryFinal
        }),
        headers: {
          "x-application-secret-key": apiXKey,
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      });

      res
        .json()
        .then(res => {
          if (res.success === 0 || res === null || res === undefined ) {
            setIsLoaded(true);
            setHasError(true);
          } else {
            console.log(res)
            setIsLoaded(true);

            // Check current_cp array = []?
            if(res.current_cp.length > 0) {

              setDataCampaign(res)
              setDataCurrentCp(res.current_cp[0])

              let dataAPIObj = {
                apiXKey: apiXKey,
                apiLocation: apiLocation,
                token: res.token,
                cp_id: res.current_cp[0].id
              }

              setDataAPI(dataAPIObj)
            } else {
              setHasError(true)
              setHasErrorMsg('ไม่มี campaign ใดๆที่สามารถเล่นได้')
            }

          }
        })
        .catch(err => {
          console.log(err);
          setHasError(true)
          setHasErrorMsg('ไม่สามารถเข้าระบบได้')
        });

    }

    fetchLogin()

  }, [query, queryFinal])

  // Check loading
  if(!isLoaded) {
    return (
      <div className="App">
        <div className="App-loading">
          <p style={{color: '#000000', fontSize: '1.4rem'}}>...Loading...</p>
        </div>
      </div>
    );

   }  
   
  // Check error campaign
  if(hasError){
    return (
      <div className="App">
        <div className="App-error">
          {/* <p>campaign ID ไม่ถูกต้อง</p> */}
          <p style={{color: '#000000', fontSize: '1.4rem'}}>{hasErrorMsg}</p>
        </div>
      </div>
    );
  } 

  // Check obj empty of dataCampaign
  if(Object.keys(dataCampaign).length === 0){
    return (
      <div className="App">
        <div className="App-error" style={{backgroundImage: 'url('+dataCurrentCp.background_picture+')'}}>
          <p style={{color: '#000000', fontSize: '1.4rem'}}>เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
        </div>
      </div>
    );
  } 

  // Check dataCampaign has data
  if(dataCampaign) {
    return (
      <div className="App">
        <Main dataCampaign={dataCampaign} dataCurrentCp={dataCurrentCp} dataAPI={dataAPI} />
      </div>
    );
  }
}

export default App;
