import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom';

import Home from './Home';
import Playgame from './Playgame';

function Main(props) {
    const { dataCurrentCp, dataCampaign, dataAPI } = props
    
    return (
        <div>
            <BrowserRouter>
                    {/* <UserAdded />  */}
                    <Route exact path="/" render={()=> <Home dataCampaign={dataCampaign} dataCurrentCp={dataCurrentCp} dataAPI={dataAPI} /> } />
                    <Route path="/playgame" render={()=> <Playgame dataCampaign={dataCampaign} dataCurrentCp={dataCurrentCp} dataAPI={dataAPI} /> } />

                </BrowserRouter>
        </div>
    )
}

export default Main
